<template>
  <div class="listing-detail-body">
    <div class="listing-detail-header">
      <div class="url-container">
        <p class="content-normal">
          {{ getUrl() }}
        </p>
        <div class="url-container-button">
          <BaseButton
            @click="copyUrl()"
            icon-left="copy.svg"
            :background-color="'#4C8DD9'"
            :icons-style="{
              filter:
                'invert(100%) sepia(0%) saturate(0%)\n' +
                '                        hue-rotate(317deg) brightness(103%) contrast(102%)',
            }"
            :label-color="'#fff'"
            :label="$t('CopyLink')"
          />
        </div>
      </div>
      <div class="url-container-button-mobile">
        <BaseButton
          @click="copyUrl()"
          icon-left="copy.svg"
          :background-color="'#4C8DD9'"
          :icons-style="{
            filter:
              'invert(100%) sepia(0%) saturate(0%)\n' +
              '                        hue-rotate(317deg) brightness(103%) contrast(102%)',
          }"
          :label-color="'#fff'"
          :label="$t('CopyLink')"
        />
      </div>
      <div class="listing-detail-options-container">
        <div class="listing-detail-view-options">
          <div class="toggle-wrapper justify-end" @click="allInfo = !allInfo">
            <img
              class="toggle-img"
              v-if="allInfo"
              src="../../assets/images/toggle-active.svg"
              alt=""
            />
            <img
              class="toggle-img"
              v-if="!allInfo"
              src="../../assets/images/toggle-inactive.svg"
              alt=""
            />
            <p class="content-normal">{{ $t("AllInfo") }}</p>
          </div>
          <div
            class="toggle-wrapper justify-end"
            @click="allImages = !allImages"
          >
            <img
              class="toggle-img"
              v-if="allImages"
              src="../../assets/images/toggle-active.svg"
              alt=""
            />
            <img
              class="toggle-img"
              v-if="!allImages"
              src="../../assets/images/toggle-inactive.svg"
              alt=""
            />
            <p class="content-normal">{{ $t("AllImages") }}</p>
          </div>
          <div
            class="toggle-wrapper justify-end"
            @click="includeFooter = !includeFooter"
          >
            <img
              class="toggle-img"
              v-if="includeFooter"
              src="../../assets/images/toggle-active.svg"
              alt=""
            />
            <img
              class="toggle-img"
              v-if="!includeFooter"
              src="../../assets/images/toggle-inactive.svg"
              alt=""
            />
            <p class="content-normal">{{ $t("IncludeFooter") }}</p>
          </div>
          <BaseButton
            @click="print()"
            :size="'large'"
            :icon-left="'download_24_24.svg'"
            :isLoading="pdfLoading"
            :icons-style="{
              filter:
                'invert(100%) sepia(0%) saturate(0%)\n' +
                '                        hue-rotate(317deg) brightness(103%) contrast(102%)',
            }"
            :label="$t('SaveAsPdf')"
            :primary="true"
          />
          <BaseButton
            @click="printHTML()"
            :size="'large'"
            :icon-left="'print.svg'"
            :label="$t('Print')"
            :primary="false"
          />
        </div>
        <LanguageToggle style="height: 40px" />
      </div>
    </div>

    <div v-if="listing" id="printMe" class="listing-detail">
      <div class="grid-container">
        <div class="grid-header">
          <div v-if="company && company.name" class="logo-container">
            <img class="logo-image" :src="company.name" alt="" />
          </div>
          <div class="listing-subheading">
            <div class="estate-info" v-if="listing.estateType">
              {{ getEstateInfo() }}
            </div>
            <div
              v-if="listing.listingName"
              class="listing-detail-content-header-name"
            >
              {{ listing.listingName }}
            </div>
          </div>
          <div class="url-container-image-mobile-only">
            <img
              v-if="listing.images && listing.images[0]"
              :style="{
                objectPosition:
                  company && company.prioritization
                    ? company.prioritization
                    : 'center',
              }"
              class="primary-image-container"
              :src="chooseCoverImage"
              alt=""
            />
          </div>
        </div>
        <div class="test-class">
          <div v-if="allInfo" class="grid-left">
            <div class="listing-detail-content-container">
              <div class="url-container-image-regular">
                <img
                  v-if="listing.images && listing.images[0]"
                  :style="{
                    objectPosition:
                      company && company.prioritization
                        ? company.prioritization
                        : 'center',
                  }"
                  class="primary-image-container"
                  :src="chooseCoverImage"
                  alt=""
                />
              </div>
              <div class="listing-detail-body-contents">
                <div v-if="allInfo" class="listing-detail-body-summary-section">
                  <div class="listing-detail-body-summary-header">
                    <p
                      v-if="this.$i18n.locale === 'et'"
                      class="listing-detail-body-summary-header-title"
                    >
                      {{ listing.slogan }}
                    </p>
                    <p
                      v-if="this.$i18n.locale === 'en'"
                      class="listing-detail-body-summary-header-title"
                    >
                      {{
                        listing.sloganENG ? listing.sloganENG : listing.slogan
                      }}
                    </p>
                    <p class="content-normal ownership-type">
                      {{ ownershipFormsAndBuildingMaterials }}
                    </p>
                  </div>
                  <div class="listings-detail-body-features-container">
                    <div
                      v-if="additionalDetails"
                      class="listings-detail-body-features"
                    >
                      <p style="display: inline">
                        {{ $t("AdditionalDetails") }}:
                      </p>
                      {{ additionalDetails }}
                    </div>
                    <div v-if="kitchen" class="listings-detail-body-features">
                      <p style="display: inline">{{ $t("Kitchen") }}:</p>
                      {{ kitchen }}
                    </div>
                    <div
                      v-if="sanitaryRoom"
                      class="listings-detail-body-features"
                    >
                      <p style="display: inline">{{ $t("SanitaryRoom") }}:</p>
                      {{ sanitaryRoom }}
                    </div>
                    <div
                      v-if="heatingAndVentilation"
                      class="listings-detail-body-features"
                    >
                      <p style="display: inline">
                        {{ $t("HeatingandVentilation") }}:
                      </p>
                      {{ heatingAndVentilation }}
                    </div>
                    <div
                      v-if="connectionAndSecurity"
                      class="listings-detail-body-features"
                    >
                      <p style="display: inline">
                        {{ $t("ConnectionAndSecurity") }}:
                      </p>
                      {{ connectionAndSecurity }}
                    </div>
                    <div
                      v-if="surroundings"
                      class="listings-detail-body-features"
                    >
                      <p style="display: inline">{{ $t("Surroundings") }}:</p>
                      {{ surroundings }}
                    </div>
                  </div>
                  <div class="description">
                    <p
                      class="listing-detail-body-description"
                      v-html="listing.description"
                    >
                      {{ listing.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-bind:class="{
              'grid-right': allInfo,
              'grid-without-info': !allInfo,
            }"
          >
            <div class="listing-detail-content-header">
              <div
                v-if="price || listing.pricePerSquareMeter"
                class="listing-detail-content-header-price-container"
              >
                <div class="listing-detail-content-header-price-wrapper">
                  <p v-if="price" class="listing-detail-content-header-price">
                    {{ price }}
                  </p>
                  <p
                    v-if="showM2Price"
                    class="listing-detail-content-header-price-per-m2"
                  >
                    {{ listing.pricePerSquareMeter }} €/m2
                  </p>
                  <p
                    v-if="showHaPrice"
                    class="listing-detail-content-header-price-per-m2"
                  >
                    {{ pricePerHa }} €/ha
                  </p>
                </div>
              </div>
              <div
                v-if="listing.estateType !== 'land'"
                v-bind:class="{
                  'listing-detail-info-cards': allInfo,
                  'listing-detail-info-cards-without-wrap': !allInfo,
                }"
              >
                <div class="listing-detail-info-card-section">
                  <div
                    class="listing-detail-info-card"
                    v-if="listing.estateType !== 'garage'"
                  >
                    <div class="svg-image" alt="" />
                    <p class="rooms-info">
                      {{ this.listing.rooms }}
                      {{
                        this.listing.rooms === 1
                          ? $t("room")
                          : $t("rooms").toLowerCase()
                      }}
                    </p>
                  </div>
                  <div class="listing-detail-info-card">
                    <div
                      class="svg-image2"
                      :src="require('@/assets/images/area_24_24.svg')"
                      alt=""
                    />
                    <p class="surface-info">
                      {{ this.listing.generalSurfaceArea }} m2
                    </p>
                  </div>
                </div>
                <div class="listing-detail-info-card-section">
                  <div
                    class="listing-detail-info-card"
                    v-if="listing.estateType !== 'garage'"
                  >
                    <div
                      class="svg-image3"
                      :src="require('@/assets/images/floor_24_24.svg')"
                      alt=""
                    />
                    <p
                      v-if="
                        listing.estateType !== 'house' &&
                        listing.estateType !== 'house_part' &&
                        listing.estateType !== 'cottage'
                      "
                      class="floor-info"
                    >
                      {{ this.listing.floor
                      }}{{ listing.estateType !== "house" ? "/" : ""
                      }}{{ this.listing.floors }}
                      {{ $t("Floor").toLowerCase() }}
                    </p>
                    <p
                      v-if="
                        listing.estateType === 'house' ||
                        listing.estateType === 'house_part' ||
                        listing.estateType === 'cottage'
                      "
                      class="floor-info"
                    >
                      {{ this.listing.floors }}
                      {{
                        this.listing.floors === 1
                          ? $t("Floor").toLowerCase()
                          : $t("FloorPlural").toLowerCase()
                      }}
                    </p>
                  </div>
                  <div class="listing-detail-info-card">
                    <div
                      class="svg-image4"
                      :src="require('@/assets/images/actions_24_24.svg')"
                      alt=""
                    />
                    <p class="built-info">
                      {{ $t("BuiltIn") }} {{ this.listing.buildYear }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="google-map-container" v-if="allInfo">
                <GmapMap
                  :center="mapCoordinates"
                  :zoom="18"
                  map-type-id="terrain"
                  class="g-map"
                >
                  <GmapMarker
                    id="marker"
                    :position="markerCoordinates"
                    :clickable="true"
                    :draggable="true"
                  />
                </GmapMap>
              </div>
              <div
                :class="{
                  'listing-detail-body-details-section': allInfo,
                  'listing-detail-body-details-section-no-info': !allInfo,
                }"
              >
                <div class="listing-detail-body-info-section">
                  <div
                    v-if="allInfo"
                    class="listing-detail-body-main-info-container"
                  >
                    <div
                      class="listing-detail-body-main-info-item"
                      v-for="item in mainInfo"
                      :key="item.key"
                    >
                      <p>
                        {{ item.key }}
                      </p>
                      <p
                        v-if="item.value === listing.cadastralNumber"
                        @click="openCadastralLink(item.value)"
                        class="content-font underline"
                      >
                        {{ item.value }}
                      </p>
                      <p
                        v-else-if="item.value === listing.estateNumber"
                        @click="openEstateNumberLink(item.value)"
                        class="content-font underline"
                      >
                        {{ item.value }}
                      </p>
                      <p v-else class="content-font">
                        {{ item.value }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="allInfo" class="listing-detail-body-brokers-section">
                  <div
                    class="listing-detail-broker-card"
                    v-for="(sharedCreator, index) in brokers"
                    :key="'broker-' + index"
                  >
                    <div class="listing-detail-broker-card-image">
                      <img
                        v-if="
                          sharedCreator.profilePicture &&
                          sharedCreator.profilePicture.length
                        "
                        style="height: 100%; width: 100%; object-fit: cover"
                        :src="sharedCreator.profilePicture[0].name"
                        alt=""
                      />
                    </div>
                    <div
                      class="listing-detail-broker-info"
                      style="display: flex; flex-direction: column; gap: 4px"
                    >
                      <p class="listing-detail-broker-name">
                        {{ sharedCreator.name }}
                      </p>
                      <p>{{ sharedCreator.phoneNumber }}</p>
                      <p>{{ sharedCreator.email }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="allImages" class="listing-images-section">
        <div
          id="lightgallery"
          @click="openImageGallery(index)"
          class="listing-image-container"
          v-for="(image, index) in renderedImages"
          :key="index"
        >
          <img class="listing-image" :src="image" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";
import i18n from "@/i18n";
import LanguageToggle from "@/components/common/LanguageToggle";
import BaseButton from "@/components/common/BaseButton";

export default {
  name: "ListingsFullDetail",
  components: {
    BaseButton,
    LanguageToggle,
  },
  data() {
    return {
      company: null,
      cadastralLink: "https://xgis.maaamet.ee/ky/",
      estateNumberLink: "https://ehr.ee/app/link?ehr=",
      hideLanguageSelection: false,
      listing: null,
      loggedIn: false,
      allInfo: true,
      allImages: true,
      includeFooter: true,
      mainInfo: [],
      price: null,
      additionalDetails: null,
      kitchen: null,
      sanitaryRoom: null,
      heatingAndVentilation: null,
      connectionAndSecurity: null,
      surroundings: null,
      descriptionWithoutFooter: null,
      descriptionWithoutFooterEng: null,
      ownershipFormsAndBuildingMaterials: null,
      creator: null,
      brokers: [],
      users: [],
      pdfLoading: false,
    };
  },
  created() {
    this.isLoggedIn();
    this.initData();
  },
  mounted() {},
  computed: {
    mapCoordinates: function () {
      return this.listing.coordinates;
    },
    showM2Price: function() {
      if (this.listing.pricePerSquareMeter) {
        /*if (this.listing.estateType === 'land') {
          if (this.listing.forestLand || this.listing.profityieldingLand) {
            return false
          } else {
            return true
          }
        }*/
        return true
      } else {
        return false
      }
    },
    showHaPrice: function() {
      if (this.listing.landArea && ((this.listing.landArea.m2 > 1000 && this.listing.landArea.m2Selected) || (this.listing.landArea.ha >= 0.1 && this.listing.landArea.haSelected)) && this.listing.estateType === 'land') {
        if (this.listing.forestLand || this.listing.profityieldingLand) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    pricePerHa: function () {
      if (
        this.listing.estateType === "land" &&
        this.listing.landArea.m2 &&
        this.listing.price &&
        this.listing.landArea.m2Selected
      ) {
        let haFromM2 = this.listing.landArea.m2 / 10000;
        let priceHa = this.listing.price / haFromM2;
        if (priceHa % 1 === 0) {
          return parseInt(priceHa);
        }
        return priceHa.toFixed(2);
      } else if (
        this.listing.estateType === "land" &&
        this.listing.landArea.ha &&
        this.listing.price &&
        this.listing.landArea.haSelected
      ) {
        let finalPrice = this.listing.price / this.listing.landArea.ha;
        if (finalPrice % 1 === 0) {
          return parseInt(finalPrice);
        }
        return finalPrice.toFixed(2);
      }
      return 0;
    },
    markerCoordinates: function () {
      return this.listing.coordinates;
    },
    chooseCoverImage: function () {
      let coverImage = [];
      if (
        this.listing.isWatermarkApplied &&
        this.listing.images[0]?.watermarkName
      ) {
        this.listing.images.forEach((image) => {
          if (!image.hidden && image.watermarkName) {
            coverImage.push(image.watermarkName);
          }
        });
      } else {
        this.listing.images.forEach((image) => {
          if (!image.hidden && image.name) {
            coverImage.push(image.name);
          }
        });
      }
      return coverImage[0];
    },
    renderedImages: function () {
      // .filter((imag) => imag.name.includes('runproperty_listing_images'))
      if (
        this.listing.isWatermarkApplied &&
        this.listing.images[0]?.watermarkName
      ) {
        return this.listing.images
          .filter((images) => !images.hidden && images.watermarkName && (images.name?.includes('runproperty_listing_images') || 
       images.name?.includes('runproperty-listing-images') || images.name?.includes('runproperty-property-developer-images')))
          .map((image) => image.watermarkName);
      } else {
        return this.listing.images
          .filter((images) => !images.hidden && (images.name?.includes('runproperty_listing_images') || 
       images.name?.includes('runproperty-listing-images') || images.name?.includes('runproperty-property-developer-images')))
          .map((image) => image.name);
      }
    },
  },
  watch: {
    includeFooter() {
      this.initDescription();
    },
  },
  methods: {
    async initData() {
      let listingId = this.$route.params.id;
      // TODO can't get response when no logged in
      let response = await axios.get(`/api/listing/public/${listingId}`);
      this.listing = response.data;
      this.descriptionWithoutFooter = this.listing.description;
      this.descriptionWithoutFooterEng = this.listing.descriptionENG
        ? this.listing.descriptionENG
        : this.listing.description;
      this.initDescription();
      /*  if (this.listing.footerEnabled && this.listing.footerText) {
        this.listing.description =
          this.listing.description +
          "\n \n" +
          this.listing.footerText.et +
          "\n \n";
      } */
      this.getCompany();
      this.initMainInfo();
      this.initAdditionalInfo();
      this.initBrokers();
    },
    initDescription() {
      if (
        this.listing.footerEnabled &&
        this.listing.footerText &&
        this.includeFooter
      ) {
        if (this.$i18n.locale === "et") {
          this.listing.description =
            this.listing.description +
            "\n \n" +
            this.listing.footerText.et +
            "\n \n";
        } else if (this.$i18n.locale === "en") {
          let descriptionEng = this.listing.descriptionENG
            ? this.listing.descriptionENG
            : this.listing.description;
          let footerTextEng = this.listing.footerText.eng
            ? this.listing.footerText.eng
            : this.listing.footerText.et;
          this.listing.description =
            descriptionEng + "\n \n" + footerTextEng + "\n \n";
        }
      } else if (!this.includeFooter) {
        if (this.$i18n.locale === "et") {
          this.listing.description = this.descriptionWithoutFooter;
        } else if (this.$i18n.locale === "en") {
          this.listing.description = this.descriptionWithoutFooterEng;
        }
      }
    },
    async getCompany() {
      console.log(this.listing.creator.companyId);
      await axios
        .get(`/api/company/public/${this.listing.creator.companyId}`)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data[0] &&
            response.data[0].companyLogo
          ) {
            this.company = response.data[0].companyLogo;
          }
        });
    },
    async initBrokers() {
      let allUsers = this.listing.access.users;
      let owner = null;
      for (const user in allUsers) {
        if (allUsers[user]["ownershipType"] === "owner") {
          owner = user;
        }
      }
      let allCreatorIds = Object.keys(this.listing.access.users);

      let response = await axios.post("/api/users-preview", allCreatorIds);
      this.users = response.data;

      let brokers = [];
      owner = this.users.find((user) => user._id === owner);
      brokers.push(owner);

      for (let user of this.users) {
        if (user._id === owner._id) {
          continue;
        }
        console.log(user);
        brokers.push({
          name: user.name,
          phoneNumber: user.phoneNumber,
          email: user.email,
          profilePicture: user.profilePicture,
        });
      }
      if (
        owner.brokerLstImage &&
        owner.brokerLstImage.length > 0 &&
        !owner.brokerLstImage[0].hidden
      ) {
        this.listing.images.push(owner.brokerLstImage[0]);
      }
      console.log("broukerid: ", brokers);
      this.brokers = brokers;
    },
    initMainInfo() {
      let generalSurfaceArea = this.listing.generalSurfaceArea
        ? `${this.listing.generalSurfaceArea} m²`
        : "";

      let floors = [this.listing.floor, this.listing.floors];
      if (floors[0] && floors[1]) {
        floors = floors.join("/");
      } else {
        if (floors[0] && !floors[1]) {
          floors = floors[0];
        } else if (floors[1] && !floors[0]) {
          floors = floors[1];
        } else {
          floors = null;
        }
      }
      this.mainInfo = [
        { key: this.$t("DealItem"), value: this.formatDealType() },
        { key: this.$t("Roomse"), value: this.listing.rooms },
        { key: this.$t("GeneralSurfaceArea"), value: generalSurfaceArea },
        { key: this.$t("AreaBuildable"), value: this.listing.areaBuildable },
        { key: this.$t("LandArea"), value: this.formatLandArea() },
        { key: `${this.$t("Floor")}/${this.$t("Floors")}`, value: floors },
        { key: this.$t("BuildYear"), value: this.listing.buildYear },
        { key: this.$t("Condition"), value: this.formatConditions() },
        { key: this.$t("OwnershipType"), value: this.formatOwnershipForms() },
        {
          key: this.$t("CadastralNumber"),
          value: this.listing.cadastralNumber,
        },
        { key: this.$t("EstateNumber"), value: this.listing.estateNumber },
        { key: this.$t("EnergyLabel"), value: this.formatEnergyLabels() },
        { key: this.$t("Limitations"), value: this.formatLimitations() },
        { key: this.$t("DetailPlan"), value: this.formatDetailedPlans() },
      ];
      if (!this.listing.showCadastralNumber) {
        this.mainInfo.splice(
          this.mainInfo.findIndex((a) => a.key === this.$t("CadastralNumber")),
          1
        );
      }
      if (!this.listing.showEstateNumber) {
        this.mainInfo.splice(
          this.mainInfo.findIndex((a) => a.key === this.$t("EstateNumber")),
          1
        );
      }
      if (this.listing.estateType !== "land") {
        this.mainInfo.splice(
          this.mainInfo.findIndex((a) => a.key === this.$t("DetailPlan")),
          1
        );
      }
      if (this.listing.estateType === "land") {
        this.mainInfo.splice(
          this.mainInfo.findIndex((a) => a.key === this.$t("BuildYear")),
          1
        );
        this.mainInfo.splice(
          this.mainInfo.findIndex(
            (a) => a.key === `${this.$t("Floor")}/${this.$t("Floors")}`
          ),
          1
        );
        this.mainInfo.splice(
          this.mainInfo.findIndex(
            (a) => a.key === this.$t("GeneralSurfaceArea")
          ),
          1
        );
        this.mainInfo.splice(
          this.mainInfo.findIndex((a) => a.key === this.$t("Roomse")),
          1
        );
        this.mainInfo.splice(
          this.mainInfo.findIndex((a) => a.key === this.$t("Condition")),
          1
        );
      }
      if (
        this.listing.estateType === "house" ||
        this.listing.estateType === "house_part" ||
        this.listing.estateType === "cottage"
      ) {
        this.mainInfo.splice(
          this.mainInfo.findIndex(
            (a) => a.key === `${this.$t("Floor")}/${this.$t("Floors")}`
          ),
          1
        );
        this.mainInfo.push({
          key: this.$t("Floors"),
          value: this.listing.floors,
        });
      }
      if (this.listing.areaUsable) {
        this.mainInfo.push({
          key: this.$t("AreaUsable"),
          value: this.listing.areaUsable + " m²",
        });
      }
      if (this.listing.priceCommunity) {
        this.mainInfo.push({
          key: this.$t("PriceCommunity"),
          value: this.listing.priceCommunity + "€",
        });
      }
      this.mainInfo = this.mainInfo.filter((item) => item.value);
    },
    initAdditionalInfo() {
      this.price = this.formatPriceToReadable();
      this.additionalDetails = this.getAdditionalDetails();
      this.kitchen = this.getKitchenDetails();
      this.sanitaryRoom = this.getSanitaryRoomDetails();
      this.heatingAndVentilation = this.getHeatingAndVentilationDetails();
      this.connectionAndSecurity = this.getConnectionAndSecurityDetails();
      this.surroundings = this.getSurroundingsDetails();

      let formsDetails = [];
      let ownershipForms = this.formatOwnershipForms();
      let formatBuildingMaterials = this.formatBuildingMaterials();
      if (ownershipForms) formsDetails.push(ownershipForms);
      if (formatBuildingMaterials) formsDetails.push(formatBuildingMaterials);

      this.ownershipFormsAndBuildingMaterials =
        this.formatDetails(formsDetails);
    },
    changeView(view) {
      this.view = view;
    },
    isLoggedIn() {
      let tokenValue =
        document.cookie
          .match("(^|;)\\s*" + "token" + "\\s*=\\s*([^;]+)")
          ?.pop() || "";

      this.loggedIn = tokenValue !== "";
    },
    formatLandArea() {
      if (
        this.listing.landArea &&
        this.listing.landArea.m2Selected &&
        this.listing.landArea.m2
      ) {
        return this.listing.landArea.m2 + " m²";
      }
      if (
        this.listing.landArea &&
        this.listing.landArea.haSelected &&
        this.listing.landArea.ha
      ) {
        return this.listing.landArea.ha + " ha";
      }
    },

    // all formats needs to go separate class
    formatConditions() {
      if (this.listing.condition === "ALACRITY_NEW_BUILDING") {
        return this.$t("NewBuilding");
      } else if (this.listing.condition === "ALACRITY_TYPE_UUSVIIMISTLUS") {
        return this.$t("NewlyDecorated");
      } else if (this.listing.condition === "ALACRITY_RENEWED") {
        return this.$t("Renovated");
      } else if (this.listing.condition === "ALACRITY_SAN") {
        return this.$t("EssentialRepairDone");
      } else if (this.listing.condition === "ALACRITY_KESKMINE") {
        return this.$t("Satisfactory");
      } else if (this.listing.condition === "ALACRITY_TYPE_KAP") {
        return this.$t("NeedsCompleteRenewal");
      } else if (this.listing.condition === "ALACRITY_TYPE_REM") {
        return this.$t("NeedsDecoration");
      } else if (this.listing.condition === "ALACRITY_READY") {
        return this.$t("Ready");
      }
    },
    formatOwnershipForms() {
      if (this.listing.ownershipForm === "PROPERTY_KORTERIOMAND") {
        return this.$t("ApartmentOwnership");
      } else if (this.listing.ownershipForm === "PROPERTY_TYPES") {
        return this.$t("CommonOwnership");
      } else if (this.listing.ownershipForm === "PROPERTY_ELAMUYHISTU") {
        return this.$t("CooperativeHousing");
      } else if (this.listing.ownershipForm === "PROPERTY_KINNISTU") {
        return this.$t("ImmovableProperty");
      } else if (this.listing.ownershipForm === "PROPERTY_VALLASASI") {
        return this.$t("MovableProperty");
      } else if (this.listing.ownershipForm === "PROPERTY_BUILDING") {
        return this.$t("RightOfSuperficies");
      } else if (this.listing.ownershipForm === "PROPERTY_MUNICIPAL") {
        return this.$t("Municipal");
      }
    },
    formatEnergyLabels() {
      if (this.listing.energyLabel === "no_certificate") {
        return this.$t("NoCertificate");
      } else if (this.listing.energyLabel === "ENERGY_CLASS_A") {
        return "A";
      } else if (this.listing.energyLabel === "ENERGY_CLASS_B") {
        return "B";
      } else if (this.listing.energyLabel === "ENERGY_CLASS_C") {
        return "C";
      } else if (this.listing.energyLabel === "ENERGY_CLASS_D") {
        return "D";
      } else if (this.listing.energyLabel === "ENERGY_CLASS_E") {
        return "E";
      } else if (this.listing.energyLabel === "ENERGY_CLASS_F") {
        return "F";
      } else if (this.listing.energyLabel === "ENERGY_CLASS_G") {
        return "G";
      } else if (this.listing.energyLabel === "ENERGY_CLASS_H") {
        return "H";
      }
    },
    formatBuildingMaterials() {
      if (this.listing.buildingMaterial === "BUILDING_MATERIAL_TYPE_KIVI") {
        return this.$t("StoneHouse");
      } else if (
        this.listing.buildingMaterial === "BUILDING_MATERIAL_TYPE_PALK"
      ) {
        return this.$t("LogHouse");
      } else if (
        this.listing.buildingMaterial === "BUILDING_MATERIAL_TYPE_PANEEL"
      ) {
        return this.$t("PanelHouse");
      } else if (
        this.listing.buildingMaterial === "BUILDING_MATERIAL_TYPE_PUU"
      ) {
        return this.$t("WoodenHouse");
      }
    },
    formatStove() {
      if (this.listing.stove === "STOVE_ELECTRIC") {
        return this.$t("ElectricalStove");
      } else if (this.listing.stove === "STOVE_GAS") {
        return this.$t("GasStove");
      } else if (this.listing.stove === "STOVE_CERAMIC") {
        return this.$t("CeramicStove");
      } else if (this.listing.stove === "STOVE_WOOD") {
        return this.$t("FireWoodStove");
      } else if (this.listing.stove === "STOVE_INDUCTION") {
        return this.$t("InductionHob");
      }
    },
    formatParkingOptions() {
      if (this.listing.parkingOption === "PARKING_FEE") {
        return this.$t("ForFee");
      } else if (this.listing.parkingOption === "PARKING_FREE") {
        return this.$t("FreeParking");
      } else if (this.listing.parkingOption === "NO_PARKING") {
        return this.$t("NoParking");
      }
    },
    formatRoofTypes() {
      if (this.listing.roofType === "ROOF_TYPE_BITUMIN") {
        return this.$t("BitumenRoof");
      } else if (this.listing.roofType === "ROOF_TYPE_ETERNIIT") {
        return this.$t("EternitRoof");
      } else if (this.listing.roofType === "ROOF_TYPE_KIVI") {
        return this.$t("StoneRoof");
      } else if (this.listing.roofType === "ROOF_TYPE_SLATE") {
        return this.$t("SlateRoof");
      } else if (this.listing.roofType === "ROOF_TYPE_TILING") {
        return this.$t("TilingRoof");
      } else if (this.listing.roofType === "ROOF_TYPE_PLEKK") {
        return this.$t("TinSheetRoof");
      } else if (this.listing.roofType === "ROOF_TYPE_PVC") {
        return this.$t("PVCRoof");
      } else if (this.listing.roofType === "ROOF_TYPE_RULL") {
        return this.$t("RolledMaterialRoof");
      } else if (this.listing.roofType === "ROOF_TYPE_TSINKPLEKK") {
        return this.$t("ZincSheetRoof");
      }
    },
    formatReservoir() {
      if (this.listing.reservoir === "WATERBODY_SEA") {
        return this.$t("Sea");
      } else if (this.listing.reservoir === "WATERBODY_LAKE") {
        return this.$t("Lake");
      } else if (this.listing.reservoir === "WATERBODY_RIVER") {
        return this.$t("River");
      } else if (this.listing.reservoir === "WATERBODY_OTHER") {
        return this.$t("OtherWaterBody");
      }
    },
    formatNeighbours() {
      if (this.listing.neighbours === "AROUND") {
        return this.$t("Around");
      } else if (this.listing.neighbours === "NEXT_TO") {
        return this.$t("NextTo");
      } else if (this.listing.neighbours === "AT_ONE_SIDE") {
        return this.$t("AtOneSide");
      } else if (this.listing.neighbours === "FARTHER") {
        return this.$t("Farther");
      }
    },
    formatBuilding() {
      if (this.listing.building === "BUILDING_PRIVATE_HOUSE") {
        return this.$t("PrivateHouses");
      } else if (this.listing.building === "BUILDING_PRIVATE_HOUSEAPART") {
        return this.$t("PrivateHousesOrApartments");
      } else if (this.listing.building === "BUILDING_APARTMENT") {
        return this.$t("ApartmentBuildings");
      } else if (this.listing.building === "BUILDING_COMMERCIAL") {
        return this.$t("CommercialBuildings");
      } else if (this.listing.building === "BUILDING_MANUFACTURE") {
        return this.$t("ManufacturingBuildings");
      } else if (this.listing.building === "BUILDING_NO") {
        return this.$t("NoBuildings");
      }
    },
    formatRoads() {
      if (this.listing.roads === "ROADS_GOOD_CONDITION") {
        return this.$t("InGoodCondition");
      } else if (this.listing.roads === "ROADS_SATISFACTORY_CONDITION") {
        return this.$t("InSatisfactoryCondition");
      } else if (this.listing.roads === "ROADS_BAD_CONDITION") {
        return this.$t("InBadCondition");
      } else if (this.listing.roads === "ROADS_PAVED") {
        return this.$t("Paved");
      } else if (this.listing.roads === "ROADS_GRAVEL") {
        return this.$t("GravelRoad");
      }
    },
    formatLocations() {
      if (this.listing.location === "LOCATION_CENTRE") {
        return this.$t("InCentre");
      } else if (this.listing.location === "LOCATION_SUBURB") {
        return this.$t("InSuburb");
      } else if (this.listing.location === "LOCATION_OUTSIDE") {
        return this.$t("OutsideTheSettlement");
      } else if (this.listing.location === "LOCATION_CITY") {
        return this.$t("InTheCity");
      }
    },
    formatDealType() {
      if (this.listing.dealType === "sale") {
        return this.$t("Sale");
      } else if (this.listing.dealType === "rent") {
        return this.$t("Rent");
      } else if (this.listing.dealType === "short_term_rent") {
        return this.$t("ShortTermRent");
      }
    },
    formatEstateType() {
      if (this.listing.estateType === "commercial") {
        return this.$t("Commercial");
      } else if (this.listing.estateType === "apartment") {
        return this.$t("Apartment");
      } else if (this.listing.estateType === "house") {
        return this.$t("House");
      } else if (this.listing.estateType === "house_part") {
        return this.$t("HousePart");
      } else if (this.listing.estateType === "land") {
        return this.$t("LandPlot");
      } else if (this.listing.estateType === "cottage") {
        return this.$t("SummerHouse");
      } else if (this.listing.estateType === "garage") {
        return this.$t("Garage");
      }
    },
    formatLimitations() {
      switch (this.listing.limitations) {
        case "CONSTRAINTS_MORTGAGE":
          return i18n.t("InMortage");
        case "CONSTRAINTS_OTHER":
          return i18n.t("OtherLimitations");
        case "CONSTRAINTS_PANT":
          return i18n.t("Pledge");
        case "CONSTRAINTS_PROTECTED":
          return i18n.t("PreservedTerritory");
        case "CONSTRAINTS_RENT":
          return i18n.t("RentAgreement");
        case "CONSTRAINTS_DISTRESS":
          return i18n.t("UnderSeizure");
      }
    },
    formatDetailedPlans() {
      if (this.listing.detailedPlan === "DETAILED_PLAN_EXIST") {
        return this.$t("Exist");
      } else if (this.listing.detailedPlan === "DETAILED_PLAN_NONE") {
        return this.$t("None");
      } else if (this.listing.detailedPlan === "DETAILED_PLAN_STARTED") {
        return this.$t("Started");
      }
    },

    getAdditionalDetails() {
      let additionalDetails = [];

      let parkingOption = this.formatParkingOptions();
      let roofType = this.formatRoofTypes();

      if (parkingOption) {
        additionalDetails.push(parkingOption);
      }
      if (roofType) {
        additionalDetails.push(`${roofType}`);
      }
      if (this.listing.garage) {
        additionalDetails.push(this.$t("Garage"));
      }
      if (this.listing.lift) {
        additionalDetails.push(this.$t("Lift"));
      }
      if (this.listing.parquet) {
        additionalDetails.push(this.$t("Parquet"));
      }
      if (this.listing.furniture) {
        additionalDetails.push(this.$t("Furniture"));
      }
      if (this.listing.furnitureAbility) {
        additionalDetails.push(this.$t("FurnitureChangePossibility"));
      }
      if (this.listing.electricity) {
        additionalDetails.push(this.$t("Electricity"));
      }
      if (this.listing.storeRoom) {
        additionalDetails.push(this.$t("Storeroom"));
      }
      if (this.listing.terrace) {
        let terrace = this.$t("Terrace");
        if (this.listing.balconySize)
          terrace += ` (${this.listing.balconySize} m²)`;
        additionalDetails.push(terrace);
      }
      if (this.listing.showCaseWindows) {
        additionalDetails.push(this.$t("ShowCaseWindows"));
      }
      if (this.listing.streetEntrance) {
        additionalDetails.push(this.$t("StreetEntrance"));
      }
      if (this.listing.trestle) {
        additionalDetails.push(this.$t("Trestle"));
      }
      if (this.listing.goodsLift) {
        additionalDetails.push(this.$t("GoodsLift"));
      }
      if (this.listing.basementFloor) {
        additionalDetails.push(this.$t("BasementFloor"));
      }
      if (this.listing.separateEntrance) {
        additionalDetails.push(this.$t("SeparateEntrance"));
      }
      if (this.listing.closedYard) {
        additionalDetails.push(this.$t("ClosedYard"));
      }
      if (this.listing.meetingRoom) {
        additionalDetails.push(this.$t("MeetingRoom"));
      }
      if (this.listing.industrialElectricity) {
        additionalDetails.push(this.$t("IndustrialElectricity"));
      }
      if (this.listing.well) {
        additionalDetails.push(this.$t("Well"));
      }
      if (this.listing.water) {
        additionalDetails.push(this.$t("Water"));
      }
      if (this.listing.sewerage) {
        additionalDetails.push(this.$t("Sewerage"));
      }
      if (this.listing.publicTransport) {
        additionalDetails.push(this.$t("PublicTransport"));
      }
      if (this.listing.gasIncluded) {
        additionalDetails.push(this.$t("GasIncluded"));
      }
      if (this.listing.road) {
        additionalDetails.push(this.$t("Road"));
      }
      if (this.listing.duplex) {
        additionalDetails.push(this.$t("Duplex"));
      }
      if (this.listing.handicappedAdaptedAccess) {
        additionalDetails.push(this.$t("HandicappedAdaptedAccess"));
      }
      if (this.listing.handicappedAdaptedUse) {
        additionalDetails.push(this.$t("HandicappedAdaptedUse"));
      }
      if (this.listing.orientationNorth) {
        additionalDetails.push(this.$t("OrientationNorth"));
      }
      if (this.listing.orientationSouth) {
        additionalDetails.push(this.$t("OrientationSouth"));
      }
      if (this.listing.orientationWest) {
        additionalDetails.push(this.$t("OrientationWest"));
      }
      if (this.listing.orientationEast) {
        additionalDetails.push(this.$t("OrientationEast"));
      }
      if (this.listing.recommendedForChildren) {
        additionalDetails.push(this.$t("RecommendedForChildren"));
      }
      if (this.listing.studio) {
        additionalDetails.push(this.$t("Studio"));
      }
      if (this.listing.handicappedAdaptedAccess) {
        additionalDetails.push(this.$t("HandicappedAdaptedAccess"));
      }
      if (this.listing.isAuction) {
        additionalDetails.push(this.$t("IsAuction"));
      }
      if (this.listing.outdoorParkingSpaceAvailable) {
        additionalDetails.push(this.$t("OutdoorParkingSpaceAvailable"));
      }
      if (this.listing.garden) {
        additionalDetails.push(this.$t("Garden"));
      }
      if (this.listing.chimney) {
        additionalDetails.push(this.$t("Chimney"));
      }
      if (this.listing.parkingAutomaticDoor) {
        additionalDetails.push(this.$t("ParkingAutomaticDoor"));
      }
      if (this.listing.parkingPlaceCovered) {
        additionalDetails.push(this.$t("ParkingPlaceCovered"));
      }
      if (this.listing.parkingPrice) {
        additionalDetails.push(
          this.$t("ParkingPrice") + " " + this.listing.parkingPrice + "€"
        );
      }
      if (this.listing.tenantNumber) {
        additionalDetails.push(
          this.$t("TenantNumber") + " " + this.listing.tenantNumber
        );
      }
      if (this.listing.minAuctionBidIncrement) {
        additionalDetails.push(
          this.$t("MinAuctionBidIncrement") +
            " " +
            this.listing.minAuctionBidIncrement
        );
      }
      if (this.listing.auctionDeposit) {
        additionalDetails.push(
          this.$t("AuctionDeposit") + " " + this.listing.auctionDeposit
        );
      }
      if (this.listing.auctionDate) {
        additionalDetails.push(
          this.$t("AuctionDate") + " " + this.listing.auctionDate
        );
      }
      if (this.listing.auctionTribunal) {
        additionalDetails.push(
          this.$t("AuctionTribunal") + " " + this.listing.auctionTribunal
        );
      }
      if (this.listing.gardenType) {
        additionalDetails.push(
          this.$t("GardenType") + " " + this.listing.gardenType
        );
      }
      if (this.listing.parkingSpaceArea) {
        additionalDetails.push(
          this.$t("ParkingSpaceArea") + " " + this.listing.parkingSpaceArea
        );
      }
      if (this.listing.outdoorParkingSpaceType) {
        additionalDetails.push(
          this.$t("OutdoorParkingSpaceType") +
            " " +
            this.listing.outdoorParkingSpaceType
        );
      }
      if (this.listing.outdoorParkingSpaceNumber) {
        additionalDetails.push(
          this.$t("OutdoorParkingSpaceNumber") +
            " " +
            this.listing.outdoorParkingSpaceNumber
        );
      }
      if (this.listing.energyCertificateLaw) {
        additionalDetails.push(
          this.$t("EnergyCertificateLaw") +
            " " +
            this.listing.energyCertificateLaw
        );
      }
      if (this.listing.floorLevel) {
        additionalDetails.push(
          this.$t("FloorLevel") + " " + this.listing.floorLevel
        );
      }
      if (this.listing.currentOccupation) {
        additionalDetails.push(
          this.$t("CurrentOccupation") + " " + this.listing.currentOccupation
        );
      }
      if (this.listing.garageCapacity) {
        additionalDetails.push(
          this.$t("GarageCapacity") + " " + this.listing.garageCapacity
        );
      }
      if (this.listing.parkingType) {
        additionalDetails.push(
          this.$t("ParkingType") + " " + this.listing.parkingType
        );
      }
      if (this.listing.buildingAdapted) {
        additionalDetails.push(this.$t("BuildingAdapted"));
      }
      if (this.listing.buildingAdapted) {
        additionalDetails.push(
          this.$t("ConditionedAirType") + " " + this.listing.conditionedAirType
        );
      }
      if (this.listing.doorman) {
        additionalDetails.push(this.$t("Doorman"));
      }
      if (this.listing.emergencyExit) {
        additionalDetails.push(this.$t("EmergencyExit"));
      }
      if (this.listing.emergencyLights) {
        additionalDetails.push(this.$t("EmergencyLights"));
      }
      if (this.listing.extinguishers) {
        additionalDetails.push(this.$t("Extinguishers"));
      }
      if (this.listing.fireDetectors) {
        additionalDetails.push(this.$t("FireDetectors"));
      }
      if (this.listing.fireDoors) {
        additionalDetails.push(this.$t("FireDoors"));
      }
      if (this.listing.floorsProperty) {
        additionalDetails.push(
          this.$t("FloorsProperty") + " " + this.listing.floorsProperty
        );
      }
      if (this.listing.officeBuilding) {
        additionalDetails.push(this.$t("OfficeBuilding"));
      }
      if (this.listing.roomsSplitted) {
        additionalDetails.push(
          this.$t("RoomsSplitted") + " " + this.listing.roomsSplitted
        );
      }
      if (this.listing.sprinklers) {
        additionalDetails.push(this.$t("sprinklers"));
      }
      if (this.listing.suspendedCeiling) {
        additionalDetails.push(this.$t("SuspendedCeiling"));
      }
      if (this.listing.suspendedFloor) {
        additionalDetails.push(this.$t("SuspendedFloor"));
      }
      if (this.listing.windowsDouble) {
        additionalDetails.push(this.$t("WindowsDouble"));
      }
      if (this.listing.areaTradableMinimum) {
        additionalDetails.push(
          this.$t("AreaTradableMinimum") +
            " " +
            this.listing.areaTradableMinimum
        );
      }
      if (this.listing.auxiliaryEntrance) {
        additionalDetails.push(this.$t("AuxiliaryEntrance"));
      }
      if (this.listing.bathroomAdapted) {
        additionalDetails.push(this.$t("BathroomAdapted"));
      }
      if (this.listing.bridgeCrane) {
        additionalDetails.push(this.$t("BridgeCrane"));
      }
      if (this.listing.facadeArea) {
        additionalDetails.push(
          this.$t("FacadeArea") + " " + this.listing.facadeArea
        );
      }
      if (this.listing.lastActivity) {
        additionalDetails.push(
          this.$t("LastActivity") + ": " + this.listing.lastActivity
        );
      }
      if (this.listing.loadingDock) {
        additionalDetails.push(this.$t("LoadingDock"));
      }
      if (this.listing.locatedAtCorner) {
        additionalDetails.push(this.$t("LocatedAtCorner"));
      }
      if (this.listing.smokeExtraction) {
        additionalDetails.push(this.$t("SmokeExtraction"));
      }
      if (this.listing.windowsNumber) {
        additionalDetails.push(
          this.$t("WindowsNumber") + ": " + this.listing.windowsNumber
        );
      }
      if (this.listing.classificationBlocks) {
        additionalDetails.push(this.$t("ClassificationBlocks"));
      }
      if (this.listing.classificationChalet) {
        additionalDetails.push(this.$t("ClassificationChalet"));
      }
      if (this.listing.classificationCommercial) {
        additionalDetails.push(this.$t("ClassificationCommercial"));
      }
      if (this.listing.classificationHotel) {
        additionalDetails.push(this.$t("ClassificationHotel"));
      }
      if (this.listing.classificationIndustrial) {
        additionalDetails.push(this.$t("ClassificationIndustrial"));
      }
      if (this.listing.classificationOffice) {
        additionalDetails.push(this.$t("ClassificationOffice"));
      }
      if (this.listing.classificationOther) {
        additionalDetails.push(this.$t("ClassificationOther"));
      }
      if (this.listing.classificationPublic) {
        additionalDetails.push(this.$t("ClassificationPublic"));
      }
      if (this.listing.naturalGas) {
        additionalDetails.push(this.$t("NaturalGas"));
      }
      if (this.listing.areaHeight) {
        additionalDetails.push(
          this.$t("AreaHeight") + ": " + this.listing.areaHeight
        );
      }
      if (this.listing.propertyTenants) {
        additionalDetails.push(
          this.$t("PropertyTenants") + ": " + this.listing.propertyTenants
        );
      }
      if (this.listing.builtProperties) {
        additionalDetails.push(
          this.$t("BuiltProperties") + ": " + this.listing.builtProperties
        );
      }
      if (this.listing.smokingAllowed) {
        additionalDetails.push(this.$t("SmokingAllowed"));
      }
      if (this.listing.minTenantAge) {
        additionalDetails.push(
          this.$t("MinTenantAge") + ": " + this.listing.minTenantAge
        );
      }
      if (this.listing.maxTenantAge) {
        additionalDetails.push(
          this.$t("MaxTenantAge") + ": " + this.listing.maxTenantAge
        );
      }
      if (this.listing.couplesAllowed) {
        additionalDetails.push(this.$t("CouplesAllowed"));
      }
      if (this.listing.bedType) {
        additionalDetails.push(
          this.$t("BedType") + ": " + this.listing.bedType
        );
      }
      if (this.listing.minimalStay) {
        additionalDetails.push(
          this.$t("MinimalStay") + ": " + this.listing.minimalStay
        );
      }
      if (this.listing.windowView) {
        additionalDetails.push(
          this.$t("WindowView") + ": " + this.listing.windowView
        );
      }
      if (this.listing.ownerLiving) {
        additionalDetails.push(this.$t("OwnerLiving"));
      }
      if (this.listing.tenantGender) {
        additionalDetails.push(
          this.$t("TenantGender") + ": " + this.listing.tenantGender
        );
      }
      if (this.listing.houseKeeper) {
        additionalDetails.push(this.$t("HouseKeeper"));
      }
      if (this.listing.privateBathroom) {
        additionalDetails.push(this.$t("PrivateBathroom"));
      }
      if (this.listing.genderPreference) {
        additionalDetails.push(
          this.$t("GenderPreference") + ": " + this.listing.genderPreference
        );
      }
      if (this.listing.occupation) {
        additionalDetails.push(
          this.$t("Occupation") + ": " + this.listing.occupation
        );
      }
      if (this.listing.lgtbFriendly) {
        additionalDetails.push(this.$t("LgtbFriendly"));
      }
      if (this.listing.occupiedNow) {
        additionalDetails.push(this.$t("OccupiedNow"));
      }
      if (this.listing.tenantWorkers) {
        additionalDetails.push(this.$t("TenantWorkers"));
      }
      if (this.listing.tenantStudents) {
        additionalDetails.push(this.$t("TenantStudents"));
      }
      if (this.listing.minAge) {
        additionalDetails.push(this.$t("MinAge") + ": " + this.listing.minAge);
      }
      if (this.listing.maxAge) {
        additionalDetails.push(this.$t("MaxAge") + ": " + this.listing.maxAge);
      }
      if (this.listing.exteriorAccessibility) {
        additionalDetails.push(this.$t("ExteriorAccessibility"));
      }
      if (this.listing.interiorAccessibility) {
        additionalDetails.push(this.$t("InteriorAccessibility"));
      }
      if (this.listing.lifeStyle) {
        additionalDetails.push(
          this.$t("LifeStyle") + ": " + this.listing.lifeStyle
        );
      }
      return this.formatDetails(additionalDetails);
    },
    getKitchenDetails() {
      let kitchenDetails = [];
      /*  const stove = this.formatStove();
       if (stove) {
         kitchenDetails.push(stove);
       } */
      if (this.listing.electricalStove) {
        kitchenDetails.push(this.$t("ElectricalStove"));
      }
      if (this.listing.gasStove) {
        kitchenDetails.push(this.$t("GasStove"));
      }
      if (this.listing.ceramicStove) {
        kitchenDetails.push(this.$t("CeramicStove"));
      }
      if (this.listing.fireWoodStove) {
        kitchenDetails.push(this.$t("FireWoodStove"));
      }
      if (this.listing.inductionStove) {
        kitchenDetails.push(this.$t("InductionHob"));
      }
      if (this.listing.openedKitchen) {
        kitchenDetails.push(this.$t("OpenKitchen"));
      }
      if (this.listing.fridge) {
        kitchenDetails.push(this.$t("Fridge"));
      }
      if (this.listing.kitchenFurniture) {
        kitchenDetails.push(this.$t("KitchenFurniture"));
      }
      if (this.listing.equipment) {
        kitchenDetails.push(this.listing.equipment);
      }

      return this.formatDetails(kitchenDetails);
    },
    getSanitaryRoomDetails() {
      let sanitaryRoomDetails = [];
      if (this.listing.bathroomInside) {
        sanitaryRoomDetails.push(this.$t("BathroomInside"));
      }
      if (this.listing.bathroomType) {
        sanitaryRoomDetails.push(
          this.$t("BathroomType") + " " + this.listing.parkingType
        );
      }
      if (this.listing.bath) {
        sanitaryRoomDetails.push(this.$t("Bath"));
      }
      if (this.listing.sauna) {
        sanitaryRoomDetails.push(this.$t("Sauna"));
      }
      if (this.listing.localWaterSupply) {
        sanitaryRoomDetails.push(this.$t("LocalWaterSupply"));
      }
      if (this.listing.centralWaterSupply) {
        sanitaryRoomDetails.push(this.$t("CentralWaterSupply"));
      }
      if (this.listing.pool) {
        sanitaryRoomDetails.push(this.$t("Pool"));
      }

      return this.formatDetails(sanitaryRoomDetails);
    },
    getHeatingAndVentilationDetails() {
      let heatingAndVentilationDetails = [];
      if (this.listing.centralGas) {
        heatingAndVentilationDetails.push(this.$t("CentralGas"));
      }
      if (this.listing.centralHeating) {
        heatingAndVentilationDetails.push(this.$t("CentralHeating"));
      }
      if (this.listing.gasHeating) {
        heatingAndVentilationDetails.push(this.$t("GasHeating"));
      }
      if (this.listing.furnaceHeating) {
        heatingAndVentilationDetails.push(this.$t("FurnaceHeating"));
      }
      if (this.listing.electricalHeating) {
        heatingAndVentilationDetails.push(this.$t("ElectricalHeating"));
      }
      if (this.listing.combinedHeating) {
        heatingAndVentilationDetails.push(this.$t("CombinedHeating"));
      }
      if (this.listing.floorHeating) {
        heatingAndVentilationDetails.push(this.$t("FloorHeating"));
      }
      if (this.listing.geothermalHeating) {
        heatingAndVentilationDetails.push(this.$t("GeothermalHeating"));
      }
      if (this.listing.firePlace) {
        heatingAndVentilationDetails.push(this.$t("Fireplace"));
      }
      if (this.listing.solidFuel) {
        heatingAndVentilationDetails.push(this.$t("SolidFuel"));
      }
      if (this.listing.airHeatPump) {
        heatingAndVentilationDetails.push(this.$t("AirHeatPump"));
      }
      if (this.listing.airWaterPump) {
        heatingAndVentilationDetails.push(this.$t("AirWaterHeatPump"));
      }
      if (this.listing.heating) {
        heatingAndVentilationDetails.push(this.$t("Heating"));
      }
      if (this.listing.hotWater) {
        heatingAndVentilationDetails.push(this.$t("HotWater"));
      }

      return this.formatDetails(heatingAndVentilationDetails);
    },
    getConnectionAndSecurityDetails() {
      let connectionAndSecurityDetails = [];
      if (this.listing.security24h) {
        connectionAndSecurityDetails.push(this.$t("Security24h"));
      }
      if (this.listing.access24h) {
        connectionAndSecurityDetails.push(this.$t("Access24h"));
      }
      if (this.listing.internet) {
        connectionAndSecurityDetails.push(this.$t("Internet"));
      }
      if (this.listing.securityDoor) {
        connectionAndSecurityDetails.push(this.$t("SecurityDoor"));
      }
      if (this.listing.staircaseLocked) {
        connectionAndSecurityDetails.push(this.$t("LockedStairway"));
      }
      if (this.listing.neighbourhoodSecurity) {
        connectionAndSecurityDetails.push(this.$t("NeighbourhoodWatch"));
      }
      if (this.listing.guard) {
        connectionAndSecurityDetails.push(this.$t("Guard"));
      }
      if (this.listing.videoSecurity) {
        connectionAndSecurityDetails.push(this.$t("VideoSurveillance"));
      }
      if (this.listing.securityAlarm) {
        connectionAndSecurityDetails.push(this.$t("SecurityAlarm"));
      }
      if (this.listing.securityCamera) {
        connectionAndSecurityDetails.push(this.$t("SecurityCamera"));
      }
      if (this.listing.securityPersonnel) {
        connectionAndSecurityDetails.push(this.$t("SecurityPersonnel"));
      }
      if (this.listing.accessControl) {
        connectionAndSecurityDetails.push(this.$t("AccessControl"));
      }

      return this.formatDetails(connectionAndSecurityDetails);
    },
    getSurroundingsDetails() {
      let surroundingsDetails = [];

      let reservoir = this.formatReservoir();
      let neighbours = this.formatNeighbours();
      let building = this.formatBuilding();
      let roads = this.formatRoads();
      let location = this.formatLocations();

      if (this.listing.ideaLocation) {
        surroundingsDetails.push(
          `${this.$t("Location")}: ${this.listing.ideaLocation}`
        );
      }
      if (this.listing.sidewalk) {
        surroundingsDetails.push(`${this.$t("Sidewalk")}`);
      }
      if (this.listing.streetLighting) {
        surroundingsDetails.push(`${this.$t("StreetLighting")}`);
      }
      if (this.listing.accessType) {
        surroundingsDetails.push(
          `${this.$t("AccessType")}: ${this.listing.accessType}`
        );
      }
      if (this.listing.nearestLocationKm) {
        surroundingsDetails.push(
          `${this.$t("NearestLocationKm")}: ${this.listing.nearestLocationKm}`
        );
      }
      if (reservoir) {
        let water = `${reservoir} ${this.$t("Near").toLowerCase()}`;
        if (this.listing.reservoirName)
          water += ` (${this.listing.reservoirName})`;
        surroundingsDetails.push(water);
      }
      if (neighbours) {
        surroundingsDetails.push(
          `${this.$t("Neighbours")} ${neighbours.toLowerCase()}`
        );
      }
      if (building) {
        surroundingsDetails.push(
          `${this.$t("Near")} ${building.toLowerCase()}`
        );
      }
      if (location) {
        surroundingsDetails.push(
          `${this.$t("Location")} ${location.toLowerCase()}`
        );
      }
      if (roads) {
        surroundingsDetails.push(`${this.$t("Roads")} ${roads.toLowerCase()}`);
      }

      return this.formatDetails(surroundingsDetails);
    },
    formatPriceToReadable() {
      //  && this.listing.currency
      if (this.listing.price || this.listing.price === 0) {
        let price = this.listing.price;
        return price
          .toLocaleString("fi-FI", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
          })
          .replace(",", ".");
      } else {
        return "";
      }
    },
    // universals functions ends here ^
    formatDetails(details) {
      details = details.map((detail, index) => {
        if (index === 0) return detail;
        return detail[0].toLowerCase() + detail.slice(1);
      });

      return details.join(", ");
    },
    openImageGallery(index) {
      let dynamicEl = [];
      this.renderedImages.forEach((image) => {
        dynamicEl.push({ src: image });
      });

      const imageWrapper = document.getElementById("lightgallery");
      if (imageWrapper) {
        window.lightGallery(imageWrapper, {
          dynamic: true,
          dynamicEl,
          index: index,
        });
        window.lgData[imageWrapper.getAttribute("lg-uid")].index = index;
      }
    },
    getEstateInfo() {
      let roomsCount = this.listing.rooms;
      let estateType = this.formatEstateType(this.listing.estateType);
      let result = [];
      result.push(estateType);
      if (this.listing.estateType !== "land") {
        if (roomsCount) {
          if (roomsCount === 1) {
            result.push(roomsCount + " " + this.$t("room"));
          } else {
            result.push(roomsCount + " " + this.$t("rooms"));
          }
          return result.join(", ");
        }
      } else {
        if (this.listing.commercialLand) {
          result.push(this.$t("CommercialLand"));
        }
        if (this.listing.industrialLand) {
          result.push(this.$t("IndustrialLand"));
        }
        if (this.listing.forestLand) {
          result.push(this.$t("ForestLand"));
        }
        if (this.listing.residentialLand) {
          result.push(this.$t("ResidentialLand"));
        }
        if (this.listing.profityieldingLand) {
          result.push(this.$t("ProfitYieldingLand"));
        }
        if (this.listing.residentialLandApartment) {
          result.push(this.$t("ResidentialLandApartments"));
        }
        if (this.listing.otherUse) {
          result.push(this.$t("OtherUse"));
        }
        if (this.listing.productionLand) {
          result.push(this.$t("ProductionLand"));
        }
        return result.join(", ");
      }
      return result[0];
    },
    getUrl() {
      return window.location.href;
    },
    copyUrl() {
      navigator.clipboard.writeText(window.location.href);
      this.toastSuccess(this.$t("LinkCopied"));
    },
    async printHTML() {
      await this.$htmlToPaper("printMe");
    },
    async print() {
      // this.hideLanguageSelection = true;
      // await new Promise(r => setTimeout(r, 1000));
      //window.print();

      this.pdfLoading = true;
      let elm = document.getElementById("printMe");
      let fileHtml = elm.innerHTML;
      const response = await axios.post(`/api/listing/preview/generate-pdf`, {
        fileHtml,
      });

      const linkSource = `data:application/pdf;base64,${response.data}`;
      const downloadLink = document.createElement("a");

      const fileName = this.listing.listingName + ".pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.pdfLoading = false;
    },
    openCadastralLink(cadastralLink) {
      window.open(this.cadastralLink + cadastralLink, "_blank");
    },
    openEstateNumberLink(estateNumber) {
      window.open(this.estateNumberLink + estateNumber, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "public/assets/ListingsFullDetail.scss";

.top-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.logo-container {
  display: flex;
  padding-bottom: 48px;
}
</style>
