var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "language-toggle"
  }, _vm._l(_vm.languages, function (_language) {
    return _c('div', {
      key: 'language-' + _language.value,
      staticClass: "language-item content-small",
      class: {
        'chosen-language': _vm.$i18n.locale === _language.value
      },
      on: {
        "click": function ($event) {
          return _vm.changeLocale(_language);
        }
      }
    }, [_vm._v(" " + _vm._s(_language.name) + " ")]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }